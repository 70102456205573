import { Notification } from '../models/Notification';

export interface MetaData {
    AffectedEntityId: number,
    AffectedEntityVestion: string,
    TimezoneName: string,
    Lcid: string,
    RedirectUrl: string,
    CurrentSubCenterIds: [],
    InvalidProperties: [],
    Notifications: Array<Notification>
}

function filterNotification(message:string) {
    // Split the message by newlines
    const lines = message.split('\n').map(line => line.trim()).filter(Boolean);

    // Create a Set to store unique lines
    const uniqueLines = new Set();

    // Filter out the repeated lines
    const filteredLines = lines.filter(line => {
        if (uniqueLines.has(line)) {
            return false;
        } else {
            uniqueLines.add(line);
            return true;
        }
    });

    // Join the unique lines back into a single string
    const result = filteredLines.join('\n');

    return result;
}

export function getFirstNotificationMessage(metaData: MetaData | null | undefined): string | null {
    if (metaData && metaData.Notifications?.length) {
        return filterNotification(metaData.Notifications[0].Message);
    } else {
        return null;
    }
}

export function isSuccess(metaData: MetaData | null | undefined): boolean {
    if (metaData === null || metaData === undefined) {
        return false;
    }

    if (metaData.Notifications.find((n) => n.Type === 0)) {
        return false;
    } else {
        return true;
    }
}