import React from 'react';
import {
     IonButton, 
     withIonLifeCycle, 
     IonImg, 
     IonSegment, 
     IonSegmentButton, 
     IonLabel,
     IonList, 
     IonItem, 
     IonHeader } from '@ionic/react';
import i18next from 'i18next';
import i18n, { changeLanguage } from '../services/I18n';
import { withRouter } from "react-router-dom";
import { logoutUser, isLogged } from '../services/UserService';
import ProfileIcon from './Svg/ProfileIcon';
import DASeparator from '../components/Core/DASeparator';
import { getAppLanguages, getBlocks, getCms, getCmsAssetUrl, getPlacePhone } from '../services/CmsService';
import { getLoggedUser } from '../services/UserService';
import GetTranslationsResponse, { AppLanguage } from '../models/backendResponses/GetTranslationsResponse';
import PhoneIcon from './Svg/PhoneIcon';
import ArrowDown from './Svg/ArrowDown';
import DAPopover from './Core/DAPopover';
import LoginResponse from '../models/backendResponses/LoginResponse';
import PSLogo from '../theme/img/logo-placeholder.png';
import RestService from '../services/RestService';

interface HeaderProps {
    history: any
    hideOnMobile?: boolean
}

interface HeaderState {
    popoverVisible: boolean;
    langPopoverVisible: boolean;
    popoverEvent: Event | undefined;
    logoSrc: string;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    isLogged: Boolean;
    user: LoginResponse | null;

    constructor(props: HeaderProps) {
        super(props);

        this.isLogged = isLogged();
        this.user = getLoggedUser();

        this.state = {
            popoverVisible: false,
            langPopoverVisible: false,
            popoverEvent: undefined,
            logoSrc: PSLogo
        };
    }

    componentDidMount(): void {
        const logoUrl = getCmsAssetUrl('donor-app-logo', `${process.env.react_app_api_url}Assets/PlaceLogo.png?id=1X`);
        const img = new Image();
        img.src = logoUrl;
    
        if (img.complete) {
            this.setState({ logoSrc: logoUrl });
        } else {
            img.onload = () => this.setState({ logoSrc: logoUrl });
        }
    }

    togglePopoverClick(e: Event) {
        if (this.state.popoverVisible) {
            this.hidePopover();
        } else {
            this.setState({ popoverVisible: true, popoverEvent: e });
        }
    }

    toggleLangPopoverClick(e: Event) {
        if (this.state.langPopoverVisible) {
            this.hideLangPopover();
        } else {
            this.setState({ langPopoverVisible: true, popoverEvent: e });
        }
    }

    hidePopover() {
        this.setState({
            popoverVisible: false,
            popoverEvent: undefined
        });
    }

    hideLangPopover() {
        this.setState({
            langPopoverVisible: false,
            popoverEvent: undefined
        });
    }

    render() {
        return (
            <IonHeader className={`header ${this.isLogged ? "header--loggedIn" : ""} ${this.props.hideOnMobile ? "ion-hide-md-down" : ""}`.trim()}>
                <div className="header__container">
                    <IonImg
                        src={this.state.logoSrc}
                        key={this.state.logoSrc}
                        alt="Logo"
                        className="ion-logo"
                        onIonError={(e: any) => e.target.src = PSLogo}
                        onClick={() => this.props.history.push(this.isLogged ? "/Dashboard" : "/")}>
                    </IonImg>

                    {this.renderLanguageSelect()}

                    {this.renderPlacePhone()}

                    {this.renderNavigation()}
                </div>
            </IonHeader>
        );
    }

    renderNavigation = () => {
        if (!this.isLogged || !this.user) return;

        return (
            <div className="header__menu">
                <IonButton
                    id='ProfileBtn'
                    fill="clear"
                    color="primary"
                    title={getCms("header.profileTitle")}
                    onClick={() => this.props.history.push("/Profile")}>
                        <div className='flex gap-little ion-align-items-center'>
                            <ProfileIcon />
                            <div>{this.user.Donor.FirstName} {this.user.Donor.LastName}</div>
                        </div>
                </IonButton>

                <DASeparator />

                <IonButton
                    id='LogoutBtn'
                    fill="clear"
                    color="primary"
                    onClick={() => logoutUser()}>
                    {getCms('common.logout')}
                </IonButton>
            </div>
        )
    }

    renderLanguageSelect = () => {
        if (!isLogged() && getAppLanguages().length > 1) {
            
            // isoCode is 2 chars, but i18next.language is 5 chars (en-CZ)
            let selelectedLanguageIsoCode = getAppLanguages().find(l => l.IsoCode === i18next.language.slice(0, l.IsoCode.length))?.IsoCode;
            
            //Check if you already used langugage, if not, use default.
            let defaultLang = getAppLanguages().find(l => l.Default)?.IsoCode ?? 'en';
            let allBlocks = getBlocks();
            let block = allBlocks.find(b => b.Page === 'languages');
            if (block) {
                let currentTranslation = block.Translations.find((t: { IsoCode: string; }) => t.IsoCode === i18next.language);
                if(!currentTranslation) {
                    let defaultTranslation = block.Translations.find(t => t.IsoCode === defaultLang);
                    if(defaultTranslation) {
                        selelectedLanguageIsoCode = defaultTranslation.IsoCode;
                    }
                }
            }

            return (
                <div className="header__language-selector">
                    <div className="header__language-selector--desktop">
                        <IonSegment className='da-segment'
                            swipeGesture={false}
                            value={i18next.language}
                            onIonChange={this.languageSwitch}>

                            {this.renderLanguageTabs()}
                        </IonSegment>
                    </div>

                    <div className="header__language-selector--mobile"  onClick={(e: any) => this.toggleLangPopoverClick(e)}>
                        {getCms(`languages.${selelectedLanguageIsoCode}`)}
                        <ArrowDown />
                    </div>
                    
                    <DAPopover visible={this.state.langPopoverVisible}
                        event={this.state.popoverEvent}
                        onDismiss={() => this.hideLangPopover()}
                        cssClass='da-popover-dropdown'>
                        <IonList className='dropdown-menu'>
                            {getAppLanguages().map((lang, i) => {
                                return (
                                    <IonItem lines="none" key={`languageItem-${i}`} detail={false} button onClick={() => {
                                        changeLanguage(lang.IsoCode);
                                        this.hideLangPopover();
                                    }}>
                                        {getCms(`languages.${lang.IsoCode}`)}
                                    </IonItem>
                                )
                            })}
                        </IonList>
                    </DAPopover>
                </div>
            )
        }
    }

    renderLanguageTabs = () => {
        return getAppLanguages().map((lang, i, arr) => {
            return (
                <React.Fragment key={`languageSegment-${i}`}>
                    <IonSegmentButton key={`languageBtn-${i}`} value={lang.IsoCode}>
                        <IonLabel className='m-none'>
                            {getCms(`languages.${lang.IsoCode}`)}
                        </IonLabel>
                    </IonSegmentButton>

                    {this.renderSeparator(i, arr)}
                </React.Fragment>
            )
        });
    }

    renderSeparator = (i: number, arr: AppLanguage[]) => {
        if (i + 1 < arr.length) {
            return <DASeparator key={`languageBtnSeparator-${i}`} />;
        }
    }

    languageSwitch = (e: any) => {
        changeLanguage(e.detail.value);
    }

    renderPlacePhone = () => {
        let phone = getPlacePhone();

        if (phone == null || phone.length === 0) {
            return null;
        }

        return (
            <div className='header__phone'>
                <IonButton
                    id='PhoneBtn'
                    fill="clear"
                    color="primary"
                    href={`tel:${phone}`}>
                        <div className='flex ion-align-items-center'>
                            <PhoneIcon />
                            <span className='text-color-black font-weight-regular'>{phone}</span>
                        </div>
                </IonButton>
            </div>
        );
    }
};  

export default withRouter(withIonLifeCycle(Header));
