import i18next from 'i18next';
import GetAllAssetsResponse from '../models/backendResponses/GetAllAssetsResponse';
import GetTranslationsResponse, { AppLanguage, Block } from '../models/backendResponses/GetTranslationsResponse';
import RestService from '../services/RestService'

let allTranslationsResponse: GetTranslationsResponse | null = null;
let allAssets: GetAllAssetsResponse | null = null;
let defaultLanguage: string | null = null;
let placePhone: string | null;

export async function initCms() {
    if (allTranslationsResponse === null) {
        allTranslationsResponse = await RestService.getTranslations();
        defaultLanguage = allTranslationsResponse.AppLanguages.find(l => l.Default)?.IsoCode ?? 'en';
    }

    if (allAssets === null) {
        allAssets = await RestService.getAllAssets();
    }

    // Id 1 == owner
    placePhone = (await RestService.getPlacePhone(1)).Phone;
}

export function getCms(key: string): string {
    let page = key.split('.')[0];
    let code = key.substring(key.indexOf('.') + 1);

    let block = allTranslationsResponse?.Blocks.find(b => b.Page === page && b.Code === code);

    if (block) {
        let currentTranslation = block.Translations.find(t => t.IsoCode === i18next.language);
        if (currentTranslation && currentTranslation.Content) {
            return currentTranslation.Content;
        } else {
            let defaultTranslation = block.Translations.find(t => t.IsoCode === defaultLanguage);
            if (defaultTranslation) {
                return defaultTranslation.Content;
            }
        }
    }

    return key;
}

export function getCmsAssetUrl(code: string, defaultCmsUrl: string): string {
    let asset = allAssets?.Assets.find(a => a.Code === code);

    if (asset && asset.Url) {
        return asset.Url;
    }

    return defaultCmsUrl;
}

export function getAppLanguages(): Array<AppLanguage> {
    return allTranslationsResponse!.AppLanguages;
}

export function getBlocks(): Array<Block> {
    return allTranslationsResponse!.Blocks;
}

export function getPlacePhone(): String | null {
    return placePhone;
}